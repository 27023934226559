import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../../components/checkout';


import UXDesign from '../../pics/ux_design_bootcamp_full-time.png'

const UXFSCH = () => (

	<div className="white grid-1-3"
	>
	<Helmet

	title="UX Design Bootcamp payments page"
	meta={[
		{ name: 'description', content: 'BCS Payments page' },
		{ name: 'keywords', content: 'payment form' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
		script={[
			{ "src": "https://js.stripe.com/v3/" }
			]}
			/>
			<div>
			<h1>UX Design Bootcamp in Barcelona Code School</h1>
			<h2>Scholarship applied</h2>
			</div>

			<div style={{
				marginTop: '2em',

			}}>
			<img 
			className='banners'
			src={UXDesign} 
			alt='UX Design Bootcamp in Barcelona Code School'/>

			</div>



			

			<h2 id="cohorts">Access</h2>
			<div>
			<div className="event" >
			<h4>5300€</h4>
			<span>
			<h2>Tuition payment</h2>
			</span>
			<span>
			<p>500€ Scholarship applied</p>
			<Link to="/register" state={{course: "UX Design Bootcamp", date:'Sept 30', cost:5300}}>
			<button>Proceed to checkout</button>
			</Link>
			</span>
			</div>
			
		

			
			</div>




			</div>


			)

export default UXFSCH
